@import '../../assets/util';

.menuOption {
  cursor: not-allowed;
  .subtitle {
    font-size: map-get($font-sizes, 200);
    color: $gray-500;
    white-space: normal;
  }

  .title {
    font-weight: 600;
    color: $gray-600;
  }
}

.menuOption.enabled {
  cursor: pointer;
  &:hover {
    background-color: $gray-200;
  }

  .subtitle {
    color: $gray-600;
  }

  .title {
    color: $gray-900;
  }
}
