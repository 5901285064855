@import '../../assets/util';

.noTranscript {
  color: $gray-700;
  :global(.inner-circle) {
    stroke: $gray-700;
  }
}

.roughTranscript {
  color: $warning;
  :global(.inner-circle) {
    stroke: $fora-purple;
  }
}

.finalTranscript {
  color: theme-color(success);
  :global(.inner-circle) {
    stroke: theme-color(success);
  }
}
