@import '../../../../assets/util';
.formContainer {
  min-width: 60rem;
  max-width: 90rem;
}
.buttonContainer {
  margin-top: 50px;
  display: flex;
  gap: 40px;
}
.errors {
  display: flex;
  gap: 12px;
  font-size: 12px;
  margin-top: 4px;
}
.label {
  display: flex;
  width: fit-content;
  font-weight: 600;
  color: #000;
  margin-bottom: 0.5rem;
}
.formField {
  margin-bottom: 1rem;
}
.checkbox {
  margin-right: 1rem;
  accent-color: $fora-purple;
}
.button {
  height: 42px;
  width: 175px;
}
.h40 {
  height: 40px;
}
.h70 {
  height: 70px;
}
.h100 {
  height: 100px;
}
