@import '../../../assets/util';

.mapContainer {
  width: 250px;
}

.loading {
  min-height: 200px;
}

.conversationName {
  font-size: 26px;
}

.collectionName,
.organizationName {
  font-size: 24px;
}
