@import '../../assets/util';

.cookieBannerContainer {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-width: 1px;
  border-radius: 5px;
  z-index: 999;
  width: 470px;
  box-shadow: 0px 4px 10px 0px #00000040;
  color: #11112b;

  @media only screen and (max-width: 420px) {
    width: 300px;
  }
}

.cookieBannerText {
  padding: 24px 32px;
  border-top: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
  background-color: #f9f8fd;
}

.cookieBannerXButton {
  color: #11112b;
  margin-left: auto;
}

.cookieBannerActions {
  justify-content: flex-start;
  padding: 16px 32px;
}

.cookieBannerRejectButton {
  border-color: #11112b;
  color: #11112b;
  width: 175px;
  text-transform: none;
}

.cookieBannerAcceptButton {
  width: 175px;
  text-transform: none;
}
