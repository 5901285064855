@import '../../../../../assets/util';

.email {
  height: 40px;
  width: 100%;
  margin: map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 2) 0;
  border: 0.5px solid $fora-purple-darker;
}
.name {
  height: 40px;
  width: 10rem;
  margin: 0 map-get($spacers, 2) map-get($spacers, 2) 0;
  border: 0.5px solid $fora-purple-darker;
}

.lock {
  position: absolute;
  top: 20%;
  right: 1.15rem;
}

.submit {
  height: 40px;
  width: 8rem;
  margin: map-get($spacers, 2);
}

.error {
  color: map-get($theme-colors, 'danger');
}
