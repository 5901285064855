@import '../../assets/util';

.logContainer {
  max-height: 200px; // make log display smaller for smaller screens
  overflow: auto;
}

.container {
  flex-direction: column; // stack on smaller screens
}

// fixed log width for desktop
@include media-breakpoint-up(md) {
  .container {
    flex-direction: row;
  }

  .logContainer {
    width: 335px;
    max-height: 400px;
  }
}
