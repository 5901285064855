@import '../../../../../assets/util';
.name {
  color: inherit;
  font-size: map-get($font-sizes, 400);
  font-weight: 600;
}

.email {
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: inherit !important;
  }
}

.editButton {
  color: $fora-purple;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  display: block !important;
  &:hover {
    color: $fora-purple !important;
    background: none !important;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  background-color: lighten($gray-300, 1%);
}

.removeButton {
  padding: 6px 24px;
  height: 42px;
  border-radius: 8px;
  background: #e3342e;
  border: 1px solid #e3342e;
  color: $fora-white;
}

.active {
  color: #4caf50;
}

.status {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.orgDropdownLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.toggle {
  margin-top: 6px;
}

.editMemberButton {
  width: 100%;
  height: 42px;
  border: 1px solid $fora-purple;

  &.save {
    color: $white;
    background-color: $fora-purple;

    &:hover {
      color: $white;
      background-color: $fora-purple-dark;
    }
  }

  &.cancel {
    color: $fora-purple;
    background-color: $white;

    &:hover {
      // border: 1px solid $fora-purple;
      // color: $fora-purple-dark;
      background-color: $gray-300;
    }
  }
}

.memberFormContainer {
  margin-top: 1.5rem;
  margin-right: 3rem;
}

.unFocused {
  opacity: 0.5;
}

.container {
  width: 100%;
  padding: 30px 0 0 0;
}
