@import '../../assets/util';

$input-margin: 0.6rem;

.wrapper {
  overflow-y: hidden;
  background-color: $gray-100;

  &.sticky {
    position: sticky;
    top: $main-nav-height;
    z-index: 2; // place nav above charts and back btn
  }
}

.toggle {
  margin-bottom: $input-margin;
  color: $fora-purple !important;
  border-color: $gray-300;
  &:hover,
  &:focus {
    border-color: $fora-purple-lighter !important;
  }
}

.collapsed .toggle {
  span {
    margin-top: 2px; // center down chevron
  }
}

.topic {
  background: $white;
  color: $gray-800;
  margin: 0 $input-margin $input-margin 0;
  padding: 0;
  border-radius: $border-radius-rounded;
  font-weight: 600;
  border-color: $gray-300;
  transition: all 0.2s linear;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &.hidden {
    visibility: hidden;
  }
}

.topicLabel {
  padding: map-get($spacers, 2) 0.75rem;
  display: flex;
  align-items: center;
}

.topic.active {
  // move padding to .topic when clear btn is active
  // allows non-active topics to have a larger click box around the label
  padding-right: 0.5rem;
  .topicLabel {
    padding-right: 0;
  }
}

.topicInput {
  opacity: 0;
  position: absolute;
}

.topicIndicator {
  margin-right: map-get($spacers, 2);
}

.clearBtn {
  background: transparent;
  border: none;
  box-shadow: none;
  margin-left: map-get($spacers, 1);
  color: $gray-800;

  $size: 1.5rem;
  height: $size;
  width: $size;

  &:hover {
    background: $gray-300;
  }
}

// generate classes for each topic color
@for $i from 1 through length($topics-colors) {
  $value: nth($topics-colors, $i);
  $main-color: nth($value, 1);

  .color#{$i - 1} {
    &.active,
    &:active,
    &:hover {
      border-color: $main-color;
    }

    .topicIndicator {
      color: $main-color;
    }
  }
}
