@import '../../assets/util';

.timeline {
  border: 1px solid #6351d8;
  border-bottom: 1px solid #000000;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
}

.wavesurfer {
  border-left: 1px solid #6351d8;
  border-right: 1px solid #6351d8;
  border-bottom: 1px solid #000000;
  position: relative;
}

.zoomLabel {
  font-size: 1rem;
  font-weight: 300;
}

.zoomSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-left: 1px solid #6351d8;
  border-right: 1px solid #6351d8;
  border-bottom: 1px solid #000000;
  border-radius: 0px 0px 5px 5px;

  & > input {
    -webkit-appearance: none;
    width: 40%;
    background: transparent;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  & > input::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 3000px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -7.5px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  & > input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #6351d8;
    border-radius: 5px;
    border: 0.2px solid #010101;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.waveButton {
  min-width: 179px;
  margin: 0.75rem 0rem;
}

.trimAudioButton {
  background-color: #fcfcfd;
  color: #6351d8;
  border: none;
  box-shadow: none;

  &:hover {
    background-color: #fcfcfd;
    color: #6351d8;
  }
}
