@import '../../../assets/util';

.termText {
  user-select: none;
  font-size: 0.9em;
  cursor: pointer;
  transition: 0.5s fill-opacity $ease-cubic;
}

.link {
  stroke: $teal;
  stroke-opacity: 0.8;
  fill: none;
  pointer-events: none;
  stroke-width: 2px;
  stroke-dasharray: 120% 120%;
  stroke-dashoffset: 120%;
  opacity: 0;
  transition: 0.5s stroke-dashoffset $ease-cubic, 0.5s opacity $ease-cubic,
    0.5s stroke $ease-cubic;
}

.glyph {
  fill: $teal;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s opacity $ease-cubic, 0.5s fill $ease-cubic;
}

.term {
  &:hover,
  &.toggledTerm,
  &.searchTerm {
    .termText {
      font-weight: bold;
      fill-opacity: 1 !important;
    }

    .link {
      stroke-dashoffset: 0%;
      opacity: 1;
      transition: 0.5s stroke-dashoffset $ease-cubic, 0.2s opacity $ease-cubic,
        0.5s stroke $ease-cubic;
    }

    .glyph {
      opacity: 1;
      transition-delay: 0.08s;
      pointer-events: all;
      cursor: pointer;
    }
  }
}

.toggledTerm {
  .link {
    stroke: $clr-indigo;
  }
  .glyph {
    fill: $clr-indigo;
  }
}
.searchTerm {
  .link {
    stroke: $clr-green;
  }
  .glyph {
    fill: $clr-green;
  }
  .termText {
    fill: $clr-green;
  }
}

.glyph:hover {
  fill: $clr-indigo-300;
  transition: 0.5s opacity $ease-cubic, 0.1s fill linear;
  transition-delay: 0;
}
