@import '../../../assets/util';

$tag-transition: $btn-transition !default;

.Tag {
  background: $gray-200;
  border-radius: 12px;
  color: $gray-700;
  display: inline-flex;
  flex-wrap: flex;
  font-size: 0.9em;
  font-weight: 600;
  justify-content: flex-start;
  transition: $tag-transition;
  vertical-align: top;

  &.no-animation {
    transition: none;
  }

  &.with-addons > *,
  &.tag-inner {
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
    line-height: 1.5;
    padding: 0 1em;
  }

  &.with-addons > * {
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &.btn-icon {
      padding: 0 0.5em;
    }
  }

  &.tag-sm {
    font-size: 0.9 * $font-size-sm;
  }

  &.tag-lg {
    font-size: 0.9 * $font-size-lg;
  }

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: 0.08em;
    font-size: 0.8em; // 10% smaller than normal
    padding-top: 0.15em;
  }

  .btn {
    border: 0 !important;

    &:active,
    &.active,
    .show > &.dropdown-toggle {
      margin-top: 0 !important;
      border: 0 !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .Tag.tag-outline-#{$color} {
    border: 1px solid $value;
    background: transparent;
  }

  .Tag.tag-#{$color} {
    background: $value;
    color: color-contrast($value);
    border: 1px solid transparent; // match the others since they have borders
  }
}

.Tag.tag-outline-bg-white {
  background: #fff;
}

@each $color, $value in $theme-colors {
  .tag--pale {
    &.tag-#{$color} {
      color: darken($value, 8%);
      background-color: mix(#fff, $value, 92%);
    }
  }
}
