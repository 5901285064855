@import '../../../assets/util';

.table {
  max-height: 80vh;
  border: 1px solid $gray-400;
  border-radius: 8px;
  overflow: auto;
}

.row {
  background-color: white;

  &:hover {
    background-color: lighten($gray-200, 1);
    cursor: pointer;
  }

  &.first {
    border-top: none;
  }

  .section {
    &.alignRight {
      * {
        margin-left: auto;
      }
    }
  }

  padding: 12px 14px 12px 14px;
  border-top: 1px solid $gray-400;
}

.headerRow {
  padding: 8px 14px 8px 14px;
  border-bottom: 1px solid $gray-400;

  padding-top: 24px;
  padding-bottom: 24px;
  font-size: map-get($font-sizes, 300);
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}
