@import '../../../assets/util';

$avatar-size: 27px;

$mouse-hover-color: $clr-yellow-500;
$in-highlight-color: $clr-lime-a700;
$in-create-highlight-color: $clr-light-green-200;
$in-active-highlight-color: $clr-light-green-200;

.TranscriptParagraph {
  position: relative;
  padding-left: $avatar-size + 12px;
  flex-grow: 1;
  .conversation-link {
    margin-top: 3px;
    opacity: 0;
  }

  &:hover {
    .transcript-timestamp {
      opacity: 1;

      &:after {
        content: attr(data-content-after); // provided by data attr on span
        white-space: nowrap;
        font-size: 1em;
        margin-left: 0.4em;
        display: inline-block;
        padding-top: 3px;
        vertical-align: top;
      }
    }

    .conversation-link {
      opacity: 1;
    }
  }

  &.hideAdditionalInfo {
    padding-left: 0;
  }
}
.word-chunk.seekChunk {
  background: $seeked-to-bg-color;

  .isInHighlight {
    background: transparent;
  }
  .isInActiveHighlight {
    background: $in-active-highlight-color;
  }
  .isInCreateHighlight {
    background: $in-create-highlight-color;
  }
}

// change color of text highlight when we are creating one
// we use a global class here for performance reason, so Transcript can just have this class added
:global(.isCreatingHighlight) {
  .word-chunk > span::selection {
    background: transparent; //$in-create-highlight-color;
  }

  .transcript-speaker::selection {
    // hide selection of speaker name when creating a highlight
    background: transparent;
  }

  .word-chunk:hover {
    background: transparent;
    .isInHighlight {
      background: $in-highlight-color;
    }

    .isInActiveHighlight {
      background: $in-active-highlight-color;
    }
    .isInCreateHighlight {
      background: $in-create-highlight-color;
    }
  }
}

.word-chunk {
  cursor: pointer;
  transition: 150ms background;
  border-radius: 2px;

  > span {
    transition: 150ms background;
  }

  &:hover {
    background: $mouse-hover-color;

    .isInHighlight {
      background: transparent;
    }
  }

  .emphasized-term {
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .isInHighlight {
    background: $in-highlight-color;
  }
  .isInActiveHighlight {
    background: $in-active-highlight-color;
  }
  .isInCreateHighlight {
    background: $in-create-highlight-color;
  }
}

.notPlayable {
  .word-chunk {
    cursor: default;

    &:hover {
      background: transparent;

      .isInHighlight {
        background: $in-highlight-color;
      }
    }
  }

  &.TranscriptParagraph:hover,
  &.TranscriptParagraph:hover .header.same-speaker {
    .transcript-timestamp:after {
      content: '';
      display: none;
    }
  }
}

/** only showing highlight text so don't color it */
.hideHighlight .word-chunk {
  .isInHighlight {
    background: transparent;
  }

  &:hover {
    .isInHighlight {
      background: transparent;
    }
  }
}

.transcript-speaker {
  margin-right: 0.4em;
  font-weight: bold;
}

.transcript-speaker-avatar {
  position: absolute;
  left: 0;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  min-width: $avatar-size;
  height: 1em;
  min-height: $avatar-size;
  border-radius: 100%;
  background: $clr-purple;
  color: #fff;
  user-select: none; // don't want to interfere with highlight
}

.transcript-timestamp {
  color: $gray-700;
  font-weight: 300;
  font-size: 0.85em;
  cursor: pointer;
  user-select: none; // don't want to interfere with highlight
}

.header.same-speaker {
  position: absolute;
  left: 0;
  top: 0;
  width: $avatar-size;
  text-align: center;

  .transcript-timestamp {
    opacity: 0;
  }
}

.TranscriptParagraph:hover .header.same-speaker {
  .transcript-timestamp {
    opacity: 1;
    &:after {
      content: '▶';
      margin-left: 0;
      text-align: right;
      padding-top: 0;
      text-align: center;
      display: block;
    }
  }
}
