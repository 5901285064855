@import '../../assets/util';

.bgImage {
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.vignette {
  box-shadow: inset 0 0px 20px rgba(0, 0, 0, 0.1); // slight vignette to help against white bg
}
