/* Simple file to include in component SCSS to get access to variables and mixins */

// import material UI colors
// all colors: https://www.materialui.co/colors
// to reference directly: $clr-blue, $clr-green-200
@import '../../node_modules/material-design-color/material-color';

$border-radius: 5px;
$border-radius-lg: 8px;
$border-radius-rounded: 30px;
$ease-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$popover-max-width: 500px;
$popover-font-size: 1rem;
$expanded-highlight-card-height: 398px;

// Bootstrap overrides

// lists
$list-group-item-padding-x: 0.5rem;
$list-group-item-padding-y: 0.4rem;

// headers
$h1-font-size: 2.2rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.35rem;
$h5-font-size: 1.2rem;
$h6-font-size: 1rem;

// colors

// TODO: replace with appropriate MUI colors in LVN-2037
$teal: #18d8c6;
$teal-darkest: desaturate(darken($teal, 34%), 8%);
$teal-darker: desaturate(darken($teal, 22%), 0%);
$teal-dark: darken($teal, 9%);
$teal-light: desaturate(lighten($teal, 17%), 10%);
$teal-lighter: lighten($teal, 38%);
$teal-lightest: lighten($teal, 48%);

$fora-purple: #4f29b7;
$fora-purple-darkest: desaturate(darken($fora-purple, 40%), 8%);
$fora-purple-darker: desaturate(darken($fora-purple, 24%), 0%);
$fora-purple-dark: darken($fora-purple, 10%);
$fora-purple-light: lighten($fora-purple, 10%);
$fora-purple-lighter: lighten($fora-purple, 34%);
$fora-purple-lightest: lighten($fora-purple, 40%);
$fora-darker-custom: #11112b;
$fora-light-custom: #b296ff;
$fora-lighter-custom: #f1eaff;
$fora-green: #20a684;
$beige: #ebe9e8;
$fora-yellow: #f7fc9e;
$fora-white: #fdfdfd;

$white: $clr-white;
$gray-100: #fbfcfd;
$gray-200: #f5f8fa;
$gray-300: #ebf1f5;
$gray-400: #dfe6ec;
$gray-500: #cad1d8;
$gray-600: #8795a1;
$gray-650: #717184;
$gray-700: #606f7b;
$gray-800: #3d4852;
$gray-900: #22292f;
$black: $clr-black;

$colors: (
  fora-purple: $fora-purple,
  fora-purple-darkest: $fora-purple-darkest,
  fora-purple-darker: $fora-purple-darker,
  fora-purple-dark: $fora-purple-dark,
  fora-purple-light: $fora-purple-light,
  fora-purple-lighter: $fora-purple-lighter,
  fora-purple-lightest: $fora-purple-lightest,
  fora-darker-custom: $fora-darker-custom,
  fora-light-custom: $fora-light-custom,
);

$theme-colors: (
  'primary': $fora-purple,
  'white': $white,
  'secondary': $gray-400,
  'success': $clr-green,
  'info': $clr-teal-600,
  'warning': $clr-yellow,
  'danger': $clr-red-700,
  'light': $gray-100,
  'dark': $gray-800,
  'gray': $gray-700,
  'fora-purple': $fora-purple,
);

$text-muted: $gray-700;

$conversation-icon-colors: (
  protected: $clr-blue-400,
  community: $clr-orange,
  public: $teal,
);

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

$link-color: theme-color('primary');
$link-hover-color: darken($link-color, 15%);
$component-active-bg: theme-color('primary');
$list-group-active-bg: $component-active-bg;

// dropdowns
$dropdown-link-color: $gray-900;
// $dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: $gray-300;
$dropdown-link-active-bg: $gray-400;
$dropdown-link-active-color: $gray-900;
$dropdown-border-color: $gray-300;
$dropdown-item-padding-x: 1rem;

// layout
$main-nav-height: 40px;

// boxes
$box-padding-1: 1.5rem;
$box-padding-2: 1rem;

// tables
$table-accent-bg: hsla(199, 25%, 38%, 0.06);

// charts
$grid-line-color: #eee;
$tick-text-color: #aaa;
$axis-line-color: #aaa;
$default-bar-color: $clr-indigo-900;

// topics colors
// background, text color
// if the number of topics changes, update the NUM_COLORS constant in src/util/topics.ts
$topics-colors: (
  ($clr-red, $white),
  ($clr-pink, $white),
  ($clr-purple, $white),
  ($clr-deep-purple, $white),
  ($clr-indigo, $white),
  ($clr-blue, $white),
  ($clr-light-blue, $white),
  ($clr-cyan, $white),
  ($clr-teal, $white),
  ($clr-green, $white),
  ($clr-light-green, $white),
  ($clr-lime, $gray-900),
  ($clr-yellow, $gray-900),
  ($clr-amber, $gray-900),
  ($clr-orange, $white),
  ($clr-grey, $white),
  ($clr-blue-grey, $white),
  ($clr-deep-orange, $white),
  ($clr-red-a700, $white),
  ($clr-pink-a700, $white),
  ($clr-purple-a700, $white),
  ($clr-deep-purple-a700, $white),
  ($clr-indigo-a700, $white),
  ($clr-blue-a700, $white),
  ($clr-light-blue-a700, $white),
  ($clr-cyan-a700, $white),
  ($clr-teal-a700, $white),
  ($clr-green-a700, $white),
  ($clr-light-green-a700, $white),
  ($clr-lime-a700, $gray-900),
  ($clr-yellow-a700, $gray-900),
  ($clr-amber-a700, $gray-900),
  ($clr-cyan-a400, $white)
);

$font-family-sans-serif: 'proxima-nova', Proxima Nova, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// fonts used in registration flow
$font-family-sans-serif-alt: open_sans, BlinkMacSystemFont, -apple-system,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$font-family-headings-alt: 'knockout', $font-family-sans-serif-alt;

$component-active-color: $white;
$component-active-bg: theme-color('primary');

$border-color: $gray-300;
$input-color: $gray-800;
$popover-border-color: $border-color;

$box-shadow-xs: 0 2px 0 rgba($black, 0.015);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.04);
$box-shadow: 0 0.2rem 0.25rem rgba($black, 0.08);
$box-shadow-md: 0 0.5rem 1.5rem rgba($black, 0.12);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$popover-box-shadow: $box-shadow;
$dropdown-box-shadow: $box-shadow;

// buttons
$btn-box-shadow: $box-shadow-xs;
$input-btn-padding-y: 0.25rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-sm: 0.2rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-padding-y-lg: 0.3rem;
$input-btn-padding-x-lg: 1.25rem;
$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($component-active-bg, 0.4);
$input-btn-focus-box-shadow: none;
$custom-select-line-height: 1; // otherwise text is a bit too low

$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $btn-box-shadow;
$btn-transition: color 0.15s linear, background-color 0.15s linear,
  border-color 0.15s linear, box-shadow 0.15s linear !default;

// input
$input-padding-x: 0.75rem;

// alerts
$alert-color-level: 8; // higher number = darker font

/////////////////////////////
// BOOTSTRAP IMPORTS. (defaults will be overridden by above)
/////////////////////////////

// Core variables and mixins
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

/////////////////////////////
// You can use all bootstrap variables below here
/////////////////////////////

$card-max-width: 750px;

$mainfilters-height: 45px;

$main-padding: 24px;

$nav-padding: 20px;
$nav-logo-right-margin: 10px;
$nav-logo-width: 40px;
$nav-logo-height: $nav-logo-width;
$nav-logo-outer-width: $nav-padding * 2 + $nav-logo-width +
  $nav-logo-right-margin;
$main-nav-height: 62px;
$main-left-nav-width: 90px;
$base-left-margin: $nav-logo-outer-width - $main-left-nav-width;

$transcript-hover-background: hsla(183, 50%, 50%, 0.12);
$transcript-hover-color: hsl(190, 79%, 20%);
$transcript-hover-highlight-color: hsl(190, 79%, 15%);

$highlight-color-curated: $teal-dark;
$highlight-color-community: $gray-600;
$highlight-color-private: $gray-600;

$seeked-to-bg-color: $clr-yellow-200;

// hearth highlight table sizes
$default-col-width: 6.5rem;
$star-col-width: 2rem;
$name-col-width: 10rem;
$name-col-width-xs: 6.5rem;
$desc-col-width: 11rem;
$duration-col-width: 5rem;
$highlight-row-height: $font-size-base * 3.5;
$highlight-row-height-xs: $font-size-base * 7;

$font-sizes: (
  // use with text-transform: uppercase
  10: 0.7rem,
  100: 0.85rem,
  200: 0.9rem,
  250: 0.95em,
  300: 1rem,
  // 1.125rem = 18px
  400: 1.125rem,
  // useful for large icons,
  1000: 1.75rem
);

@mixin transcript-hover {
  background: $transcript-hover-background;
  color: $transcript-hover-color;

  &.text-type-complete-match {
    color: $transcript-hover-highlight-color;
  }
}

@mixin panel {
  padding: 2rem;
  padding-top: 1.5rem;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 2px hsl(208, 23%, 85%);
}

// popover overrides
$popover-border-color: $gray-300;
$popover-header-bg: $white;

// modal
$modal-overlay-background: rgba(255, 255, 255, 0.7);
$modal-content-background: $white;

// side filters
$filter-width: 15rem;

// tint taken from Bourbon
@function tint($color, $percent) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `tint` mixin.";
  } @else {
    @return mix(#fff, $color, $percent);
  }
}

// shade taken from Bourbon
@function shade($color, $percent) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `shade` mixin.";
  } @else {
    @return mix(#000, $color, $percent);
  }
}

// exports to JS-land
:export {
  white: $white;
  black: $black;
  foraPurple: $fora-purple;
  foraPurpleDark: $fora-purple-dark;
  foraPurpleDarker: $fora-purple-darker;
  foraPurpleLight: $fora-purple-light;
  foraPurpleLighter: $fora-purple-lighter;
  foraPurpleLightest: $fora-purple-lightest;
  foraLightCustom: $fora-light-custom;
  foraLighterCustom: $fora-lighter-custom;
  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  gray650: $gray-650;
  gray700: $gray-700;
  gray800: $gray-800;
  gray900: $gray-900;
  tealLight: $teal-light;
  teal: $teal;
  tealDark: $teal-dark;
  blue: $clr-blue;
  red: $clr-red;
  danger: $clr-red-700;
}
