@import '../../../assets/util';

.waveButton {
  min-width: 179px;
  margin: 0.75rem 0rem;
}

.trimAudioButton {
  background-color: #fcfcfd;
  color: #6351d8;
  border: none;
  box-shadow: none;

  &:hover {
    background-color: #fcfcfd;
    color: #6351d8;
  }
}
