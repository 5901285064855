@import '../../assets/util';

.starHighlightButton {
  :global(.Icon) {
    color: $gray-500;
  }
  &:hover {
    :global(.Icon) {
      color: $gray-600;
    }
  }
  &:focus,
  &:active {
    :global(.Icon) {
      color: $gray-700;
    }
  }
  &.starred {
    :global(.Icon) {
      color: $clr-yellow-600;
    }
  }
}
