@import '../../assets/util';

.container {
  background-color: $beige;
  font-size: 1rem;
  border-radius: 100%;
  padding: 0;
  width: 2rem;
  height: 2rem;
  color: $fora-purple;

  &:focus,
  &:hover,
  &.active {
    background: $fora-light-custom;
  }

  :global(#HW_badge_cont) {
    :global(#HW_badge) {
      // place badge on top right of notification circle
      top: -4px;
      left: 22px;
    }
    :global(.HW_visible) {
      background: $fora-darker-custom;
      opacity: 1;
    }
    :global(.HW_softHidden) {
      opacity: 0;
    }
  }

  .bell {
    position: absolute;
    cursor: pointer;
  }
}

:global(#HW_frame_cont) {
  margin-top: map-get($spacers, 2);
}
