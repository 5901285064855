@import '../../../assets/util';

.MultiSelect {
  &.focus .multi-select-container {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  }

  .multi-select-container {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 0 $input-padding-x $input-padding-y; // no padding top since selected values need it as margin for when they wrap
    font-size: $font-size-base;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    display: flex;
  }

  .inputs {
    flex: 1;
  }

  .controls {
    margin: 0 (-$input-padding-x) (-$input-padding-y);
    flex: 0;
    .btn {
      color: #495057;
      height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-top: 0;
      padding-bottom: 0;
      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: darken(#495057, 30%);
        background: $gray-100;
      }
    }
  }

  input {
    border: none;
    padding: 0;
    margin: $input-padding-y 0 0;
    outline: none;
  }

  .selected-value {
    display: inline-block;
    background: $gray-300;
    color: $gray-900;
    padding: 0px 0.5em;
    margin: $input-padding-y 0.375em 0px 0;
    border-radius: $border-radius;
  }

  .remove-selected {
    font-weight: bold;
    font-size: 1.5em;
    padding: 0 0.3em;
    margin-right: -0.3em;
    line-height: 0;
    cursor: pointer;
    opacity: 0.8;
    vertical-align: middle;

    &:hover {
      opacity: 1;
    }
  }

  .dropdown-menu {
    width: 100%;
    max-height: 250px;
    overflow: auto;

    // disable hover and rely on the active class being added
    .dropdown-item.no-hover {
      &:hover {
        background: inherit;
        color: inherit;
      }

      &.active,
      &:active {
        color: $dropdown-link-active-color;
        text-decoration: none;
        @include gradient-bg($dropdown-link-active-bg);
      }
    }
  }

  .highlighted {
    background: none;
    font-weight: 800;
    color: inherit;
    border-bottom: 1px solid $dropdown-link-active-bg;
    padding: 0;
  }
  .active .highlighted {
    border-bottom-color: $dropdown-link-active-color;
  }
}
