@import '../../../../../assets/util';
.panel {
  padding: 0 !important;
  max-height: 40vh;
  overflow: auto;
  margin-bottom: 0 !important;
}

.tableHeader {
  padding: 16px 0;
  margin: 0 !important;
  font-size: map-get($font-sizes, 300) !important;
  position: sticky !important;
  top: 0;
  z-index: 2; // Needs to be 2 instead of 1 so that toggle is below it
  background-color: white;
}

.title {
  text-decoration: underline;
}
