@import '../../../assets/util';

$avatar-size: 40px;

.transcript_speaker_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: $avatar-size;
  height: 40px;
  min-height: $avatar-size;
  border-radius: 100%;
  background: $clr-black;
  font-weight: bold;
  color: #fff;
  margin-right: 1rem;
  user-select: none; // don't want to interfere with highlight
}

.transcript_speaker_row {
  height: 40px;
  min-height: $avatar-size;
  display: flex;
  align-items: center;
}
