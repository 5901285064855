@import '../../../../../assets/util';
.uploadConversations {
  position: relative;
}

.icon {
  position: absolute;
  left: 4.5rem;
  top: 0.175rem;
}
