@import '../../assets/util';
// adapted from https://github.com/react-dropzone/react-dropzone/blob/master/examples/theme.css

.container {
  display: flex;
  flex-direction: column;
}

.icon {
  font-size: 3rem;
  color: $gray-600;
}

.mainText {
  font-size: map-get($font-sizes, 400);
  font-weight: bold;
}

.block > div > p {
  margin-bottom: 0;
}

.block {
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $gray-500;
  border-style: dashed;
  background-color: $gray-100;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
}

.block:hover,
.block:focus {
  border-color: $fora-purple;
}

.block.disabled {
  opacity: 0.6;
}
