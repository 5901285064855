@import '../../../assets/util';

.ruler {
  rect {
    fill: $gray-200;
  }

  line {
    stroke: $gray-400;
  }

  text {
    fill: $gray-600;
    user-select: none;
    font-size: 12px;
  }
}
