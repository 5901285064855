@import '../../../assets/util';

.actions {
  white-space: nowrap;

  // undo card padding px-3
  padding: map-get($spacers, 2) map-get($spacers, 3);
  margin-left: -1 * map-get($spacers, 3);
  margin-right: -1 * map-get($spacers, 3);
  margin-top: map-get($spacers, 3);

  display: flex;
  align-items: center;
  justify-content: space-between;

  :global(.btn) {
    vertical-align: top; // seee when toggling hearth highlight status in highlights to the input field, the play button becomes unaligned without this
  }
}
