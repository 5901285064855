@import '../../assets/util';

.mainNav {
  background-color: $fora-purple;
  min-height: $main-nav-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1061;
  display: flex;

  @include media-breakpoint-down(sm) {
    max-height: 85vh;
    overflow-y: auto;
  }

  .navBody {
    position: relative;
    padding: 14px $nav-padding;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .navLink {
    color: $beige;
    font-weight: 600;
    padding: 0.5rem 0.7rem;
    text-decoration: none;
    border-radius: 8px;
    display: block;

    @include media-breakpoint-only(md) {
      // keep lvn logo from bumping into links
      // !important to apply to nav dropdown
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }

    @include media-breakpoint-up(md) {
      margin-right: map-get($spacers, 1);
    }

    &:active,
    &.active {
      color: $beige;
    }

    &:hover {
      color: $white;
      background: $fora-purple-dark;
    }
  }

  .navSection {
    flex: 1 0 0;
  }

  .navLinks {
    margin-top: -0.5rem; // counter vertical padding on the links
    margin-bottom: -0.5rem;

    :global(.dropdown-toggle)::after {
      font-size: 1.1rem; // fixes flat caret point on chrome
      margin-bottom: -2px; // adjust caret height
    }
  }

  @include media-breakpoint-down(sm) {
    :global(.collapse.show),
    :global(.collapsing) {
      .navSection {
        padding-top: 1.25rem;
      }

      .navLinks {
        margin-top: 0;
        margin-bottom: 0;
      }

      :global(.dropdown-toggle)::after {
        margin-left: 0.4em;
      }

      :global(.dropdown.show) .navLink {
        background: $gray-200;
        color: $gray-900;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      :global(.dropdown-menu) {
        background: $gray-200;
        box-shadow: none;
        border: none;
        margin-top: -0.75em;
      }
    }
  }

  .navLogoLink {
    position: absolute;
    left: $nav-padding;
    top: 12px;

    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .navLogo {
    width: $nav-logo-width;
    height: $nav-logo-height;
  }

  .rightLinks {
    display: flex;
    justify-content: flex-end;
  }

  .accountDropdown {
    /** settings for the user nav icon button */
    .btnNavControl {
      font-size: 1rem;
      border-radius: 100%;
      padding: 0;
      width: 2rem;
      height: 2rem;
      line-height: 0;
      color: $fora-purple;
      background: none;
      box-shadow: none;

      &:focus,
      &:hover,
      &.active {
        background: $fora-light-custom;
        border-color: $fora-light-custom;
      }
    }

    .dropdown.show .btnNavControl {
      background: $gray-300;
    }

    .userAvatar {
      background: $beige;
      font-size: 1rem;
    }
  }

  .accountDropdownMenu:global(.show) {
    :global(.dropdown-item) {
      &:hover {
        background-color: $fora-light-custom;
      }
    }
  }

  .hostDropdownMenu:global(.show) {
    // show host resources at bottom of the for hosts dropdown on mobile
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }

    :global(.dropdown-item) {
      &:hover {
        background-color: $fora-light-custom;
      }
    }
  }
}

.navHeader {
  padding-top: map-get($spacers, 2);
  border-top: 1px solid $beige;
  margin: map-get($spacers, 2);

  @include media-breakpoint-down(sm) {
    color: #4afed1;
  }
}
