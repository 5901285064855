@import '../../assets/util';

.customErrorOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    64,
    64,
    64,
    0.5
  ); // so opacity is different from child element
  color: $black;
  z-index: 100;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.errorInfoContainer {
  opacity: 1;
  position: absolute;
  margin: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(
    255,
    255,
    255,
    1
  ); // so opacity is different from parent element
  border: 1px solid $white;
  padding: 1rem;
  color: $red;
}

.okButton {
  margin-top: 1rem;
}
