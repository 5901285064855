@import '../../assets/util';
@import '../../assets/registration';

// mobile elements should fill page
.container,
.form {
  $min-height-desktop: 25rem;
  $min-height-mobile: 100%;

  min-height: $min-height-desktop;

  @include media-breakpoint-down(xs) {
    height: $min-height-mobile;
  }

  > :global(.main-content) {
    @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 1) !important;
    }
  }

  h1 {
    text-align: center;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    min-width: 4rem;
  }

  input[type='email'],
  input[type='password'],
  input[type='text'] {
    width: 100%;
  }
}

.container {
  max-width: 55ch;
}
