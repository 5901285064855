@import '../../../assets/util';

.highlightItem {
  color: $fora-green;
}

.conversationItem {
  color: theme-color(fora-purple);
}

.activityItem {
  line-height: 1.3;
  &:not(:last-child) {
    padding-bottom: 16px;
  }
}

.bulletPoint {
  margin-top: 5px; // align the bullet point with the middle of the word
  font-size: 9px;
}

.relativeDate {
  font-size: map-get($font-sizes, 100);
}
