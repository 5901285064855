@import '../../../assets/util';

.barChartWrapper {
  width: 100%;
  @include media-breakpoint-down(sm) {
    position: absolute;
    left: 0;
  }
}

.backBtn {
  position: absolute;
  left: 0;
  z-index: 1;
}

.moreBtn {
  min-width: 16.25rem;
}

// generate classes for each topic color
@for $i from 1 through length($topics-colors) {
  $value: nth($topics-colors, $i);
  $main-color: nth($value, 1);
  $text-color: nth($value, 2);

  .keyword#{$i - 1} {
    :global(.barchart-bar) {
      fill: $main-color;
    }
    :global(.barchart-text) {
      fill: $text-color;
    }
  }

  .topic#{$i - 1} {
    cursor: pointer;
    &:hover {
      :global(.barchart-bar) {
        fill: $main-color;
        $shadow-offset: 0.3rem;
        filter: drop-shadow($shadow-offset $shadow-offset 0 $default-bar-color);
        transition: all 0.2s ease-in-out;
      }
      :global(.barchart-text) {
        fill: $text-color;
      }
    }
  }
}
