@import '../../../../assets/util';

.rowExpansion td {
  border-top: 0 !important; // overwrite table with-row-borders style
}

.expandRowBtn {
  color: $gray-800;
  margin-left: -14px; // align caret with table header
  margin-top: -3px;
  padding-right: 0;
  justify-content: start;
  width: 100%;
  text-align: left;

  &:disabled {
    border: none;
    color: $gray-800;
    opacity: 1;
  }
}
.row {
  margin: 0 !important;
  &:focus,
  &:focus-within,
  &:hover {
    background-color: lighten($gray-200, 1);
  }
}
.viewRow {
  height: 60px;
  padding: 16px 0 !important;
  margin: 0 !important;
}

.active {
  .expandRowBtn {
    // overwrite btn styles
    color: $fora-purple !important;
    font-weight: bold !important;
  }
}

.editForm {
  width: 100%;
  max-width: 550px;
}

.removeCollectionBtn.disabled {
  color: $gray-600;
  background: $gray-200;
  cursor: not-allowed;
  pointer-events: all;
}

.copyLinkBtn {
  justify-content: start;
  width: 9rem;
}
