.panel {
  padding: 0 !important;
  border-radius: 8px !important;
  border: 1px solid #dfe6ec;
  margin-bottom: 16px;
}

.header {
  height: 50px;
  background-color: #f4f8fa;
  border-radius: 8px 8px 0px 0px;

  &.closed {
    border-radius: 8px;
  }
}

.title {
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  font-weight: 700;
}

.chevron {
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: calc(var(--bs-gutter-x) * 0.5);
}

.table {
  max-height: 40vh;
}

.tableHeader {
  padding: 16px 14px 16px 14px;
}
