@import '../../../assets/util';

.passwordToggleButton {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.allowMarketingLabel {
  @include media-breakpoint-down(sm) {
    // overwrite label size for mobile
    font-size: map-get($map: $font-sizes, $key: 100) !important;
  }
}
