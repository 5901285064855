@import '../../assets/util';

.conversationCardsContainer {
  width: 100%;
  max-width: $card-max-width;
}

.overlay {
  z-index: 2; // hides absolutely positioned icons
  position: fixed; // covers whole screen even with scroll
}
