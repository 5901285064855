@import '../../../assets/util';

.LoadingOverlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: background-color 0.3s ease-in-out;

  &.active {
    opacity: 1;
    background-color: #fff;
  }

  &.fixed {
    z-index: 2; // hides absolutely positioned icons
    position: fixed; // covers whole screen even with scroll
  }
}
