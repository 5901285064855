@import '../../assets/util';

.playing {
  box-shadow: inset 4px 0 $fora-purple;
}

.conversationBtnContainer {
  min-width: 3.5rem;
  position: relative;
}

.conversationBtn {
  position: absolute;
  right: 0;
}

.metadata {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.term::after {
  content: '';
  position: absolute;
  height: 2px;
  top: 100%;
  width: 90%;
  left: 46%;
  transform: translateX(-50%);
  background: black;
}
