@use 'sass:math';
@import '../../assets/util';

$seek-color: $teal;
$duration-bar-color: $gray-400;

.AudioControls {
  $bar-height: 5px;
  $interaction-bar-height: 12px;
  background: transparent;

  font-size: 14px;
  color: $gray-700;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 0;
  border-radius: $bar-height;
  font-variant-numeric: lining-nums tabular-nums;

  .play-pause-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 18px;
    padding: 0 8px;
  }

  .play-status-bar {
    flex: 1;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .duration-bar,
  .seek-bar {
    height: $bar-height;
    border-radius: $bar-height;
  }

  .duration-time {
    width: 3em;
    text-align: center;
  }
  .seek-time {
    width: 3em;
    text-align: center;
  }

  .interaction-bar {
    flex: 1;
    padding: math.div($interaction-bar-height - $bar-height, 2) 12px;
    // background: $gray-700;
  }

  .duration-bar {
    background-color: $duration-bar-color;
    position: relative; // for seek-control
    transition: 0.3s background-color;
  }

  &.loading .duration-bar {
    background-color: $gray-200;
  }

  .seek-bar {
    background-color: $seek-color;
  }

  .LoadingSpinner {
    &:before,
    &:after {
      border-width: 2px;
    }

    &:before {
      border-color: $seek-color;
    }
  }
}
