@import '../../assets/util';

.container {
  width: $filter-width;
  overflow: hidden;

  .collapse {
    max-height: 25rem;
    position: relative;
  }
}

.filterToggle {
  background: $white !important;
  border: none;
}
