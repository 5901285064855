@import '../../../../../assets/util';
.panel {
  padding: 0 !important;
  max-height: 80vh;
  overflow: auto;
  position: relative;
}

.tableHeader {
  padding: 16px 0;
  margin: 0 !important;
  font-size: map-get($font-sizes, 300) !important;
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: white;
}
