// base styles for registration-related pages
// in line with lvn.org styles

@import './util';

.layout {
  // registration specific fonts (alt font families)
  font-family: $font-family-sans-serif-alt !important;

  h1 {
    font-weight: 400;
    font-family: $font-family-headings-alt !important;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  label {
    font-size: 0.9rem;
  }

  $h1-font-size-alt: 1.8rem;
  $h2-font-size-alt: 1.2rem;
  $h3-font-size-alt: 1rem;
  $h4-font-size-alt: 0.9rem;
  // TODO update to be in line with others
  $h5-font-size-alt: 1rem;
  $h6-font-size-alt: 0.9rem;

  h1 {
    // font-size: $h1-font-size-alt;
  }
  h2 {
    font-size: $h2-font-size-alt;
  }
  h3 {
    font-size: $h3-font-size-alt;
  }
  h4 {
    font-size: $h4-font-size-alt;
  }
  h5 {
    font-size: $h5-font-size-alt;
  }
  h6 {
    font-size: $h6-font-size-alt;
  }

  label {
    color: $gray-800;
    font-weight: 600;
  }

  input[type='email'],
  input[type='password'],
  input[type='text'] {
    background: $gray-300 !important;
    line-height: 2em !important;
    height: 3em !important;
  }

  // hide native password reveal in IE/edge
  input[type='password']::-ms-reveal {
    display: none;
  }
}
