@import '../../../../assets/util';

.unregistered {
  color: lighten($fora-purple, 10);
}
.active {
  color: lighten(map-get($theme-colors, 'success'), 10);
}
.inactive {
  color: lighten(map-get($theme-colors, 'danger'), 10);
}
.button {
  color: $fora-purple;
  padding: 0 map-get($spacers, 2);
  box-shadow: none;
  border: none;
  text-align: left;
  &:hover {
    color: darken($fora-purple, 30);
  }
  &:disabled {
    color: $fora-purple;
    padding: 0 map-get($spacers, 2);
    box-shadow: none;
    border: none;
    text-align: left;
    opacity: 1;
    &:hover {
      color: $fora-purple;
    }
  }
}
.text {
  padding: 0 map-get($spacers, 2);
  font-weight: 700;
}
.activated {
  padding: 0 map-get($spacers, 2);
  color: #4caf50;
  font-weight: 700;
}

.unActivated {
  padding: 0 map-get($spacers, 2);
  color: $black;
}
