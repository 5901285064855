@import '../../../assets/util';

.bgRect {
  fill: $gray-200;
}

.highlightIcon {
  fill: $clr-green;
}

.highlightIcon_community {
  fill: $clr-green-400;
}

.highlightsBarLabel {
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  fill: $gray-600;
  font-weight: bold;
  user-select: none;
  pointer-events: none;
}

.highlightsBarLabelG {
  cursor: pointer;
  &:hover {
    .highlightsBarLabel {
      fill: $gray-900;
    }
  }
}

.highlightG {
  cursor: pointer;

  &:hover {
    .highlightIcon {
      fill: $clr-green-700;
    }
  }
}
