@import '../../assets/util';

.checkboxWrapper {
  // overwrite default label styles
  color: $gray-900 !important;
  font-weight: 500 !important;
  font-size: map-get($map: $font-sizes, $key: 200) !important;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  @include media-breakpoint-down(sm) {
    // overwrite label size for mobile
    font-size: map-get($map: $font-sizes, $key: 100) !important;
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;

    &:focus {
      display: block !important;
    }
  }

  &:hover {
    .checkbox {
      border-color: $gray-900;
    }
  }

  // focus state for keyboard users
  :global(input[type='checkbox'].focus-visible) + .checkbox {
    border-width: 2px;
    border-color: $gray-900 !important;
  }
}

.checkbox {
  $size: 1.5rem;
  height: $size;
  width: $size;
  border: 1px solid $gray-500;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.checkIcon {
  font-size: 70%;
  opacity: 0;

  &.checked {
    opacity: 1;
  }
}
