@import '../../../assets/util';

.downloadBtn {
  :global(.Icon) {
    color: $gray-500;
  }
  &:hover {
    :global(.Icon) {
      color: $gray-600;
    }
  }
  &:focus {
    :global(.Icon) {
      color: $fora-purple;
    }
  }
}
