@import '../../assets/util';
.transcriptContainer {
  overflow: hidden;
}

.transcript {
  // overwrite Transcript styles
  border: none !important;
  box-shadow: none !important;
  padding: 0 0 1.75rem 0 !important;
}

.moreBtn {
  // overwrite default btn and insights-btn styles
  border-radius: $border-radius-rounded;
  box-shadow: none;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, map-get($spacers, 3));
}

.icon {
  font-size: map-get($font-sizes, 300);
}
