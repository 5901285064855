@import '../../../../../assets/util';

.header {
  font-weight: 600;
  color: $gray-700;
}
.w300 {
  width: 300px;
}
.w600 {
  max-width: 600px;
}
.spacer {
  width: 16px;
}
.h40 {
  height: 40px;
}

.label {
  font-weight: 600;
  color: $gray-700;
  margin-bottom: 8px;
}

.error {
  width: 100%;
  text-align: center;
  color: map-get($theme-colors, 'danger');
}
