@import '../../../../../assets/util';

.row {
  width: 100%;
  &:hover,
  &:focus,
  &:focus-within {
    background-color: lighten($gray-200, 1);
    .editButton {
      visibility: visible;
    }
  }
}
.viewRow {
  width: 100%;
  margin: 0 !important;
  height: $font-size-base * 6;
}

.editButton {
  &:hover {
    cursor: pointer;
  }
}

.expandRowBtn {
  // overwrite btn styles
  color: $fora-purple !important;
}

.finishEditContainer {
  transform: translateY(-50%); // vertically center btns
}

.rowError {
  // align error message below cancel/save btns
  margin-top: 1.4rem !important;
  margin-left: 3rem;
}

.shieldIcon {
  margin-bottom: 2px;
}

.formGroup {
  margin-bottom: 0 !important;
  display: flex;
  .inputGroup {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    width: 300px;
  }

  &.withHelperText {
    margin-top: -1.4rem;
  }
}

.hasFieldError {
  .withHelperText {
    margin-top: 0;
  }
}
