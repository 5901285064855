// import variables
@import './util'; // app specific overrides

// import bootstrap
@import './bootstrap_custom';

@import './tabs.scss';
@import './skip-nav.scss';
@import './fonts.scss';

@import '../../node_modules/animate.css/animate.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.min.css';

html,
body,
#root,
.App {
  min-height: 100%;
  background: #fbfcfd;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// container for Layouts
#reach-skip-nav {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available; // handles iOS safari footer
}

.SimpleLayout > .main-content {
  padding: $main-padding;
  margin-left: $base-left-margin - $main-padding;
}

.hidden {
  display: none !important;
}

button:focus,
.btn:focus,
.btn.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 5px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

// should move to be shared:
.inline-select {
  display: inline-block;
  height: calc(1.25em + 2px);
  line-height: 1.25;
  position: relative;
  vertical-align: top;
  padding-left: 0px;
  border-radius: 2px;

  &:after {
    /* the arrow */
    border: 1px solid theme-color('primary');
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;

    margin-top: -0.375em;
    right: 0.7em;
    top: 50%;
    z-index: 4;
  }

  /* hide the default select arrows */
  select::-ms-expand {
    display: none;
  }

  select:focus,
  select:active {
    box-shadow: none;
  }

  select {
    /* hide the default select arrows */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    height: calc(1.25em + 2px);
    line-height: 1.25;
    padding: 0;
    cursor: pointer;
    display: block;
    font-size: 1em;
    padding-right: 2em;
    padding-left: 4px;
    font-weight: 300;
  }
}

// subtle button used in document controls (e.g. radio play/stop)
.btn.btn-subtle-gray {
  color: $gray-600;
  background: $gray-100;
  font-weight: normal;
  padding: 0px 6px;

  &:hover {
    background: $gray-200;
    color: $gray-700;
  }

  &:active {
    background: #fff;
  }
}

// for modals
body.ReactModal__Body--open {
  overflow: hidden; // no scrolling on body when modal is open.
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $modal-overlay-background;
  overflow: auto;
  z-index: 2000;
  text-align: center; // center the content
}
.ModalContent {
  background: $modal-content-background;
  text-align: left; // undo center from modal overlay
  padding: $box-padding-1;
  border-radius: $border-radius-lg;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.is-loading {
    background: transparent;
  }

  > .LoadingSpinner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .LoadingSpinner {
      // &:after {
      //   border-color: $white transparent transparent; // line color
      // }
      // &:before {
      //   border-color: rgba(255, 255, 255, 0.2); // fill color
      // }
    }
  }
}

.modal-close-button.btn.btn-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  color: #000;
  opacity: 0.8;
  font-size: 2.5rem;
  box-shadow: none;
  cursor: pointer;
  z-index: 2002;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
    background: transparent;
    color: #fff;
  }
}

.custom-select-lg {
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='none' stroke='%23343a40' d='M0 3L6 9L12 3'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 12px;
  font-weight: 300;
}

.btn-link.disabled {
  text-decoration: none;
}

// popover overrides
.popover-header {
  border: none;
}

.popover {
  box-shadow: $popover-box-shadow;
}

.card {
  border-top: 1px solid rgba(0, 0, 0, 0.02);
  background: $white;
}

.panel {
  @include panel();

  &.panel-no-header {
    padding-top: $box-padding-1;
  }
}

.panel-head-with-desc {
  margin-bottom: 0;
}

.panel-desc {
  color: $gray-600;
  font-size: 1.1em;
}

.page-header {
  margin-bottom: map-get($spacers, 4);

  h1 {
    font-size: 1.5rem;
    color: $gray-800;
    margin-bottom: 0;
  }

  .subhead {
    color: $gray-700;
    font-weight: 300;

    b {
      font-weight: 800;
    }
  }
}

.smaller-icon {
  font-size: 0.75em;
}

.starred {
  color: $clr-yellow-500;
  font-weight: 900; // solid
}

.labeled-list {
  @extend .list-unstyled;

  .labeled-list-item-header {
    white-space: nowrap;
    padding-right: 1em;
    padding-left: 0;
    line-height: 0;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $gray-600;
    vertical-align: middle;
    font-size: 0.8em;
  }

  .labeled-list-item-value {
    width: 100%;
    padding-bottom: 15px;
    padding-top: 5px;
    padding-left: 0;
  }

  li:last-child .labeled-list-item-value {
    padding-bottom: 0;
  }
}

.inset {
  box-shadow: inset 0 -1px 4px hsl(208, 23%, 85%);
  background: $gray-100;
}

.main-full-width {
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 2rem 0;

  &:first-child {
    margin-top: -2rem;
  }
}

.rounded-8 {
  border-radius: 8px;
}

.form-label {
  font-size: 0.9em;
  font-weight: bold;
  color: $gray-700;
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.Button.btn--icon-only .fa-play {
  margin-left: 2px; // try to better re-center a play icon in a button
}

.small-header {
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: $gray-700;
}

// animations
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-spinning {
  animation: spin 1s linear infinite;
}

@keyframes pulse {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(112%);
  }
  100% {
    filter: brightness(100%);
  }
}

@keyframes bgSpin {
  0% {
    background-position: 0% -50%;
    background-size: 200% 150%;
  }
  100% {
    background-position: 100% 50%;
    background-size: 200% 150%;
  }
}

// Typography
/* prettier-ignore */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-bottom: 1rem;
}

.h1,
h1 {
  font-size: $h1-font-size;
}
.h2,
h2 {
  font-size: $h2-font-size;
}
.h3,
h3 {
  font-size: $h3-font-size;
}
.h4,
h4 {
  font-size: $h4-font-size;
}
.h5,
h5 {
  font-size: $h5-font-size;
}
.h6,
h6 {
  font-size: $h6-font-size;
}
.p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: normal;
}

.underline {
  text-decoration: underline;
}

// Forms
label {
  margin-bottom: 0em;
}

// Tables

.table {
  margin-bottom: 0;

  th {
    padding-bottom: 0;
  }

  td,
  th {
    border-top: 0;
    vertical-align: middle;
  }

  &.with-row-borders {
    td {
      border-top: 1px solid $table-border-color;
    }
  }

  thead th {
    border-bottom: 1px solid $table-border-color;
    color: $gray-700;
    font-size: map-get($font-sizes, 100);
  }
}

.form-control.w-auto {
  width: auto;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

.clickable {
  cursor: pointer !important;

  &:hover {
    opacity: 0.9;
  }
}

.subheader {
  margin-top: -0.4rem; // undo h1 margins (0.5rem)
}

// Buttons
.btn {
  font-weight: 400;
  box-shadow: $btn-box-shadow;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .Icon {
    font-size: 0.85em;

    &.fa-loading,
    &.fa-loading-dark,
    &.fa-loading-light {
      font-size: 1em;
    }
  }
}

.btn--rounded {
  border-radius: $border-radius-rounded;
}

.btn--compact {
  padding: 0 0.6em;
}

.btn--circle {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  padding: 0;
}
.btn--icon-only {
  width: 2em;
  .Icon {
    line-height: 1.5;
  }
}

.btn-link {
  box-shadow: none;
  padding: 0;
  vertical-align: baseline;
  border: 0;
}

.btn-default,
.btn-plain {
  color: $gray-700;
  border: 1px solid $gray-400;

  &:focus,
  &:hover {
    color: $gray-800;
    border-color: $gray-600;
  }

  &:active,
  &.active {
    color: theme-color('primary');
    border-color: theme-color('primary');
  }

  &.disabled,
  &:disabled {
    color: $gray-700;
    border: 1px solid $gray-400;
  }
}

.btn-plain {
  border: none;
  box-shadow: none;
  background-color: none;
  &:active,
  &:hover,
  &.active,
  &.hover,
  &:focus {
    border: none;
    box-shadow: none;
    background-color: none;
  }
}

.btn--gray-outline {
  border-color: $gray-400;
}
.btn--white-bg {
  background-color: $white;
}

// button update
@mixin button-custom-variant(
  $background,
  $border: $background,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  &:hover,
  &:focus {
    color: color-contrast($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    box-shadow: $btn-box-shadow;
  }
}

@mixin button-custom-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color
) {
  &:hover,
  &:focus {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
    box-shadow: none;
  }
}

// theme color additions
@each $color, $value in $theme-colors {
  // buttons
  .btn.btn-#{$color} {
    @include button-custom-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-custom-outline-variant($value);
  }
}

$utilities: (
  'text': (
    property: color,
    values: $conversation-icon-colors,
  ),
);

.mb-2_5 {
  margin-bottom: 0.75rem !important;
}

.font-weight-medium {
  font-weight: 600 !important;
}

.height-font-size {
  height: 1em;
}

.section-title {
  margin-bottom: map-get($spacers, 2);
  font-weight: 700;
  font-size: $h5-font-size;
}

.section-subtitle {
  color: $gray-700;
  display: block;
  font-size: map-get($font-sizes, 300);
  margin-bottom: map-get($spacers, 3);
}

.two-column-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

// TODO: this styling should be worked into the Button component as the new style of buttons becomes part of the design system
.insights-btn {
  background: $white !important;
  border-color: $gray-300;
  color: $gray-700;
  font-size: map-get($font-sizes, 100);
  font-weight: 600;

  .insights-icon,
  .Icon {
    color: $fora-purple;
  }

  &:focus,
  &:hover {
    color: $fora-purple !important;
    border-color: $fora-purple-lighter !important;
  }
}
