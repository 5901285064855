@import '../../assets/util';

.dropdownButton {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  color: $gray-500;
  &:focus {
    box-shadow: none;
  }
  &.hover {
    color: $gray-500;
  }
  &:hover {
    color: $gray-600;
  }

  &:focus,
  &:active,
  &.active {
    color: $gray-700;
  }
}
