@import '../../assets/util';

.menuOption {
  .title {
    color: $gray-900;
  }
  .subtitle {
    color: $gray-600;
  }
}

.crossPollinationLink {
  padding-left: 10px;
  overflow: hidden;
  max-width: $popover-max-width - 96px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.crossPollinationButton {
  vertical-align: text-bottom;
}
