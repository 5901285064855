@import '../../../assets/util';

.createHighlightPopover {
  max-width: 90vw;
  width: 400px;
}
.fixTranscriptPopover {
  max-width: 90vw;
  width: 600px;
}

.controlsBox {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  // to prevent it jumping the layout of paragraphs
  // when a snippet is short
  position: absolute;
  transform: translateY(-50%); // center vertically
}
