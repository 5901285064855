@import '../../assets/util';
.Layout {
  flex-grow: 1;
  padding-top: $main-nav-height;
  display: flex;
  > .main-content {
    box-shadow: none;
    background: $gray-100;
    padding: 1.5rem 0.5rem 0.5rem;
    flex: 1;
    z-index: 1;
    /* needed to get box shadow on top of side columns */
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
  .banner {
    position: absolute;
    top: $main-nav-height;
    left: 0;
    right: 0;
    z-index: 2; // above main-content
    + .main-content {
      margin-top: 3rem;
    }
  }
}
