@import '../../../assets/util';

.privacyIcon {
  margin-top: 3px;
}

.menuOption {
  cursor: pointer;

  .activeIcon {
    display: none;
  }

  &.active {
    border: 1px solid $fora-purple;
    border-radius: $border-radius;

    .activeIcon {
      display: block;
      color: $fora-purple;
    }
  }
  &:hover {
    background: $fora-purple-lightest;
  }
}
