@import '../../assets/util';

.toolbar {
  border: none !important;
  padding: 0 !important;
  justify-content: flex-end !important;

  :global(.rdw-dropdown-wrapper):hover {
    box-shadow: none !important;
    border-color: #c1baf0 !important;
  }
}

.editor {
  min-height: 6rem;
  :global(.public-DraftStyleDefault-block) {
    margin: 0;
  }
}

:global(.rdw-dropdown-optionwrapper),
.toolbarLinkBtn {
  border: 1px solid $gray-400 !important;
  border-radius: $border-radius !important;
  color: $gray-900;

  // style btn caret
  $caret-size: 4px;
  $caret-color: $gray-800;
  :global(.rdw-dropdown-carettoclose),
  :global(.rdw-dropdown-carettoopen) {
    margin-top: 2px;
    border-left: $caret-size solid transparent;
    border-right: $caret-size solid transparent;
  }
  :global(.rdw-dropdown-carettoopen) {
    border-top: $caret-size solid $caret-color;
  }
  :global(.rdw-dropdown-carettoclose) {
    border-bottom: $caret-size solid $caret-color;
  }
}

:global(.rdw-dropdown-optionwrapper) {
  margin-top: map-get($spacers, 1);
}

.linkPopup {
  border-radius: $border-radius;
  box-shadow: 0 0.2rem 0.25rem rgba(0, 0, 0, 0.08) !important;
  // visually hides original label ('Link Target') and replaces it with 'URL'
  label[for='linkTarget'] {
    font-size: 0;
    &:before {
      font-size: map-get($font-sizes, 200);
      content: 'URL';
    }
  }

  :global(.rdw-link-modal-target-option) {
    // hide "open link in new window" option
    display: none;
  }

  :global(.rdw-link-modal-buttonsection) {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  :global(.rdw-link-modal-btn) {
    background-color: $gray-400;
    border: 1px solid transparent;
    border-radius: $border-radius;
    color: $gray-900;
    margin-left: 0;
    width: 49%;

    &:disabled {
      opacity: 0.65;
    }

    &:hover,
    &:focus {
      background-color: #c7d3de;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    }

    &:first-of-type {
      background-color: $fora-purple;
      color: $white;
      &:hover,
      &:focus {
        background-color: #4732d2;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      }
      &:disabled {
        background-color: $fora-purple;
      }
    }
  }

  :global(.rdw-link-modal-input) {
    // TODO: use vars
    display: block;
    width: 100%;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3d4852;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dfe6ec;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #3d4852;
      background-color: #fff;
      border-color: #c1baf0;
      outline: 0;
      box-shadow: none;
    }
  }
}
