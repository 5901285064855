@import '../../assets/util';

.danger {
  background-color: map-get($theme-colors, 'danger');
  color: $clr-white;
}

.cancel {
  background-color: map-get($theme-colors, 'secondary');
  color: black;
}

.button {
  margin: map-get($spacers, 2);
  min-width: 12rem;
}

.modal {
  font-size: $h5-font-size;
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: $h5-font-size;
  }
}
