@import '../../../../assets/util';

.conversationLocationRegion {
  fill: theme-color('primary');
  stroke: theme-color('primary');
  stroke-width: 1px;
  fill-opacity: 0.1;
  stroke-opacity: 0.5;
}

:global(.mapboxgl-ctrl-top-left) {
  width: 100%;
}

:global(.mapboxgl-ctrl-geocoder) {
  box-shadow: none !important;
  width: 100%;

  input {
    border: 1px solid $gray-400;
    border-radius: $border-radius;
  }
}

:global(.mapboxgl-ctrl-geocoder--input),
:global(.suggestions) {
  font-family: $font-family-sans-serif !important;
}
