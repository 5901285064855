@import '../../assets/util';

.container {
  width: 360px;
  white-space: normal;
}

.helperText {
  font-size: map-get($font-sizes, 200);
  color: $gray-800;
}
