@import '../../assets/util';

$dropdown-x-padding: 1.2rem;
$dropdown-y-padding: 0.25rem;

.organization-label,
.organization-dropdown-container :global(.dropdown-toggle) {
  box-shadow: none;
  font-weight: bold;
  padding: 0.25rem 0.8rem;
}

.organization-dropdown-container > .btn {
  border: none;
}

.organization-dropdown-container :global(.dropdown-header) {
  color: $gray-700;
  padding: $dropdown-y-padding $dropdown-x-padding;
  letter-spacing: normal;
}

.organization-dropdown-container :global(.dropdown-menu) {
  width: 360px;
  max-height: 50vh;
  overflow-y: scroll;

  @include media-breakpoint-down(sm) {
    max-width: 360px;
    width: 90vw;
  }
}

.organization-dropdown-container :global(.dropdown-item) {
  white-space: break-spaces;
  padding: $dropdown-y-padding $dropdown-x-padding;
}

.organization-dropdown-container :global(.dropdown-divider) {
  border: 1px solid $gray-300;
}

.dropdownToggle {
  box-shadow: none !important;
  &:focus,
  &:hover {
    color: $fora-purple;
  }
  padding: 0;
}
