@import '../../assets/util';

.searchInputContainer {
  max-width: 500px;
}

.conversationCardsContainer {
  max-width: $card-max-width;
  margin: 0 auto;
}

.headerContainer {
  min-height: 5rem;
}
