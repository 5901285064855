@import '../../../../assets/util';

.panel {
  // center table and allow to extend past layout
  padding: 0 !important;
  max-height: 80vh;
  overflow: auto;
  position: relative;
  min-width: 80rem;
  width: 95%;

  &.loading {
    z-index: -1; // behind overlay while loading
  }
}
.tableHeader {
  padding: 16px 0 !important;
  margin: 0 !important;
  font-size: map-get($font-sizes, 300) !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: white;
}
