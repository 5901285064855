@import '../../../../assets/util';

.name {
  color: $fora-purple;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: lighten($gray-300, 1%);
}
