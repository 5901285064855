@import '../../../assets/util';

.conversationFinishedHeading {
  font-size: 1.7rem;
  margin-top: 0.5rem;
}

.conversationFinishedContainer {
  max-width: 600px;
}

.recordingCompleteFooter {
  max-width: 375px;
  margin-top: 65px;
}

.conversationLink {
  color: blue;
  cursor: pointer;
}
