@import '../../assets/util';

.searchContainer {
  margin-bottom: map-get($spacers, 4);

  @include media-breakpoint-up(lg) {
    float: right;
    width: 30vw;
    max-width: 300px;
  }
}

.fontSizeSpan {
  background-color: $fora-purple;
  @include media-breakpoint-up(md) {
    white-space: nowrap;
  }
}

.transcriptButton {
  width: 9rem;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.headingContainer {
  :global(.custom-control-input) {
    ~ :global(.custom-control-label):hover {
      opacity: 0.8;
      cursor: pointer;
    }
    ~ :global(.custom-control-label)::before {
      background-color: darken($gray-300, 2%) !important;
    }
    ~ :global(.custom-control-label)::after {
      background-color: lighten($gray-500, 2%) !important;
    }
  }
  :global(.custom-control-input):checked {
    ~ :global(.custom-control-label)::before {
      background-color: $fora-purple-lighter !important;
      border-color: darken($fora-purple-lighter, 10%) !important;
      border-width: 1px;
    }
    ~ :global(.custom-control-label)::after {
      background-color: $fora-purple !important;
    }
  }
}
