@import '../../../assets/util';

.legend {
  font-size: map-get($font-sizes, 100);
  @include media-breakpoint-down(md) {
    justify-content: center !important;
  }
}

.header::before {
  content: '';
  @include media-breakpoint-up(md) {
    flex: 1;
  }
}

.header {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.legendWrapper {
  flex: 1;
}

// generate classes for each topic color
@for $i from 1 through length($topics-colors) {
  $value: nth($topics-colors, $i);
  $main-color: nth($value, 1);

  .color#{$i - 1} {
    color: $main-color;
  }

  .underline#{$i - 1}::after {
    background: $main-color;
  }
}
