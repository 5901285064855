@import '../../assets/util';

.pageBtn {
  min-width: 2rem;
}
.pageBtn:hover,
.pageBtn:focus {
  background-color: $fora-purple-lighter;
  color: $fora-purple-dark;
  border: 1px solid $fora-purple-lighter;
}

.spacer {
  color: $gray-700;
  pointer-events: none;
}

.nextButton,
.prevButton {
  color: $gray-700;
  border: none;
  font-size: 2em;
  width: 0.7em;
}

.nextButton:hover,
.prevButton:hover,
.nextButton:focus,
.prevButton:focus {
  color: $fora-purple-light;
}

.prevButton:active,
.nextButton:active {
  color: $fora-purple-dark;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
