@import '../../../assets/util';

.playhead {
  cursor: ew-resize;

  path {
    fill: $teal;
  }

  line {
    stroke: $teal;
  }
}
