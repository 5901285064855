@import '../../../assets/util';

.turnRect {
  transition: 0.15s fill linear, 0.15s fill-opacity linear;

  &:hover {
    fill-opacity: 1 !important;
  }
}

.isFacilitator {
  .turnRect {
    fill: $gray-500;
  }
}

.isParticipant {
  .turnRect {
    fill: $gray-600;
  }
}

.speakerTrack {
  .turnRect {
    cursor: pointer;
    &:hover {
      fill: $clr-yellow;
    }
  }
}

.speakerHover {
  .turnRect {
    fill: $teal-light;
  }

  .speakerName {
    fill: $gray-700;
  }
}

.speakerToggled {
  .turnRect {
    fill: $teal;
  }

  .speakerName {
    font-weight: bold;
  }
}

.speakerName {
  pointer-events: none;
  user-select: none;
}

.speakerNameBg {
  cursor: pointer;
}

.hasSearchedTerm,
.hasToggledTerm {
  .turnRect {
    fill-opacity: 0.5;
  }
}

.turnRect.hoveredTerm {
  fill: $teal;
  fill-opacity: 1;
}
.turnRect.toggledTerm {
  fill: $clr-indigo;
  fill-opacity: 1;
}
.turnRect.searchedTerm {
  fill: $green;
  fill-opacity: 1;
}
.turnRect.isSeekedTo {
  fill: $clr-yellow-400;
  fill-opacity: 1;
}

.trackBg {
  fill: #fff;
  fill-opacity: 0;
}
.speakerTrack:hover {
  .trackBg {
    fill-opacity: 0.4;
    fill: $gray-300;
  }

  .speakerName {
    font-weight: bold;
  }
}
