@import '../../assets/util';

$audio-bar-height: 34px;

.fixedAudioPlayer {
  box-shadow: 0 -1px 4px hsl(208, 23%, 85%);

  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: #fff;

  color: $body-color;
  z-index: $zindex-fixed;

  font-size: 0.85rem;
}

.inner {
  margin: 0 auto;
}

.playBtn {
  max-width: 70px;
}

.description {
  color: $gray-700;
  line-height: 1;
  font-size: map-get($font-sizes, 300);
  text-align: center;
  padding-left: 8px; // matches AudioControls padding
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.host {
  color: $gray-800;
}

.closeBtn {
  position: absolute;
  right: 0;
  float: none;
  z-index: 1;
  width: 35px;
}

.playbackSpeedBtn {
  // target the dropdown button
  button {
    width: 40px;
  }
}

.leftControls {
  min-width: 90px;
}

.conversationTitle {
  display: block;
  padding-right: 4px;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.conversationMeta {
  font-size: map-get($font-sizes, 200);
}
