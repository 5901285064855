@import '../../assets/util';

.editModalInner {
  @media (min-width: 600px) {
    // for setting the textarea min-size
    min-width: 550px;
  }
}

.warningIcon {
  color: $clr-yellow-600;
  font-size: 1.2em;
}

.dotsButton {
  color: $gray-500;
  &:hover {
    color: $gray-600;
  }

  &:focus,
  &:active,
  &.active {
    color: $gray-700;
  }
}

.topIcons {
  margin-top: -1 * map-get($spacers, 2);
  margin-right: -1 * map-get($spacers, 2);
}

.topIcons :global(.btn) {
  margin-right: map-get($spacers, 1);
  &:last-child {
    margin-right: 0;
  }

  :global(.Icon) {
    font-size: 1.2em;
  }
}

.scrollTranscript {
  max-height: 180px;
  overflow-y: auto;
}

.transcriptToggler {
  margin-top: -0.25rem;
}

.emphasizedTerm {
  font-weight: bold;
  padding: 0;
  background: none;
}

.description {
  overflow: scroll;
}

.modalCloseBtn {
  $margin: map-get($spacers, 2);
  position: absolute;
  right: 0;
  top: 0;
  margin-top: $margin;
  margin-right: $margin;
}
