@import '../../assets/util';

.dropdownButton {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }

  &:hover .caretIcon,
  &.open .caretIcon {
    color: $fora-purple;
  }
}

.caretIcon {
  color: $gray-800;
}
