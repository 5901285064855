@import '../../../../../assets/util';
.name {
  color: $fora-purple;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: lighten($gray-300, 1%);
}

.borderless {
  border: 0;
}

.tr {
  margin: 0 !important;
  &:hover {
    background-color: lighten($gray-200, 1);
  }
}

.full {
  height: 100%;
}
