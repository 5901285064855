@import '../../../assets/util';

.flatpickrInput {
  background: $white !important;
}

// unset bold hr and input focus state
:global(.flatpickr-hour),
:global(.flatpickr-minute),
:global(.flatpickr-am-pm) {
  font-weight: normal !important;

  &:focus,
  &:hover {
    background: $white !important;
  }
}
