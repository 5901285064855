@import '../../../assets/util';

.button {
  border-radius: 0 !important;
  width: 100%;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  &:hover {
    background-color: $clr-grey-400;
  }
}
.background {
  &:hover {
    background-color: inherit !important;
  }
}
.popover {
  width: 16rem;
  padding: 0.5rem;
}
.icon {
  color: $fora-purple;
  margin-right: 0.25rem;
}
.smallFont {
  font-size: map-get($font-sizes, 200);
}
.warning {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;
}
