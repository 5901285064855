@import '../../../assets/util';

.toggle {
  input {
    &:hover,
    &:focus {
      cursor: pointer;
    }

    &.largeInput {
      height: 1.5rem;
      width: 3rem;
    }
  }
}
