.fixedWidth {
  min-width: 60rem;
  max-width: 90rem;
}

.section {
  width: 50%;
}

.bold {
  font-weight: bold;
}

.headerContainer {
  padding-right: 72px;
}
