@import '../../../assets/util';

// file select styling adapted from:
// https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/

.profileImageInput {
  // for accessibility, we can't just do hidden: none
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.nullProfileImage {
  font-size: map-get($font-sizes, 1000);
}

.profileImageLabelButton:focus,
.profileImageLabelButton:hover {
  cursor: pointer;
}
