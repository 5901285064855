@import '../../../assets/util';

.button,
.expandedContent {
  display: inline-block;
  vertical-align: middle;
}

.button {
  padding: 1rem;
  display: inline-flex;
  align-items: center;
  color: $fora-purple !important; // inactive text is usually grey, but if text is visible then the btn should be considered active

  $size: 3rem;
  border-radius: $border-radius-rounded;
  height: $size;

  .expandedContent {
    max-width: 0;
    overflow: hidden;
    transition: all 0.2s;
    margin-right: 0;
    white-space: nowrap;
  }

  &.expanded,
  &:hover,
  &:global(.focus-visible) {
    .expandedContent {
      margin-right: map-get($spacers, 2);
      max-width: 100rem; // set lg max-width for transition
    }
  }
}

.icon {
  font-size: map-get($font-sizes, 300);
}
