@import '../../../assets/util';

.overlay {
  z-index: 1;
}

.panel {
  padding-top: map-get($spacers, 1) !important;
  padding-bottom: map-get($spacers, 1) !important;
}

.form {
  width: 100%;
  max-width: 550px;
}
