@import '../../assets/util';

$dropdown-x-padding: 1.2rem;
$dropdown-y-padding: 0.25rem;

.collection-label,
.collection-dropdown-container :global(.dropdown-toggle) {
  box-shadow: none;
  font-weight: bold;
  padding: 0.25rem 0.8rem;
}

.collection-dropdown-container > .btn {
  border: none;
}

.collection-dropdown-container :global(.dropdown-header) {
  color: $gray-700;
  padding: $dropdown-y-padding $dropdown-x-padding;
  letter-spacing: normal;
}

.collection-dropdown-container :global(.dropdown-menu) {
  width: 360px;
  max-height: 50vh;
  overflow-y: scroll;

  @include media-breakpoint-down(sm) {
    max-width: 360px;
    width: 90vw;
  }
}

.collection-dropdown-container :global(.dropdown-item) {
  white-space: break-spaces;
  padding: $dropdown-y-padding $dropdown-x-padding;
}

.collection-dropdown-container :global(.dropdown-divider) {
  border: 1px solid $gray-300;
}

.centeredMenu {
  top: auto !important;
  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate(-50%) !important;
  -o-transform: translate(-50%) !important;
  transform: translate(-50%) !important;
}

.search {
  padding: 0.5rem 0.9rem; // line up the placeholder text
}

.dropdownToggle {
  box-shadow: none !important;
  &:focus,
  &:hover {
    color: $fora-purple;
  }
}

// caret that is usually applied through .dropdown-toggle::after
// but we want it to immediately follow text
.caret {
  $border-size: 0.2em;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.2em;
  content: '';
  border-top: $border-size solid;
  border-right: $border-size solid transparent;
  border-bottom: 0;
  border-left: $border-size solid transparent;
}

.inlineItemIcon {
  margin-top: -4px;
}

.itemIconContainer {
  margin-right: 7px;

  svg {
    color: $fora-purple !important;
  }
}
