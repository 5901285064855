@import '../../../../assets/util';

textarea.descriptionTextarea:global(.form-control) {
  min-height: 5rem;
}

.originalText {
  max-height: 7rem;
  overflow: auto;
}

.thankYouBox {
  padding: map-get($spacers, 3);
  text-align: center;
}

.thankYouIcon {
  font-size: 2.5rem;
}

.thankYouMessage {
  max-width: 250px;
  margin: 0 auto 1rem;
  font-size: 1.3rem;
}

.thankYouDisclaimer {
  max-width: 300px;
  margin: 0 auto;
  font-size: 0.9rem;
  color: $gray-600;
}
