@import '../../assets/util';

.tabContainer {
  max-width: $card-max-width;
  width: 100%;
}

.highlightCardsContainer {
  width: 100%;
}
