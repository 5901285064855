@import '../../assets/util';

// generate classes for each topic color
@for $i from 1 through length($topics-colors) {
  $value: nth($topics-colors, $i);
  $main-color: nth($value, 1);
  $text-color: nth($value, 2);

  .color#{$i - 1} {
    background: $main-color;
    color: $text-color;
  }
}
