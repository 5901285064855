@import '../../../../assets/util';

.dropdownToggle {
  color: $gray-900;
  box-shadow: none;

  &:hover,
  &:focus {
    border-color: #c1baf0; // form-control:focus color
  }
}
