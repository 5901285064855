@import '../../assets/util';

.container {
  max-height: 25rem;
  overflow-y: scroll;
}

.fade {
  position: absolute;
  bottom: 0px;
  pointer-events: none;
  display: block;
  width: 85%;
  height: 6.3rem;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 80%
  );
}

.buffer {
  height: 1rem;
}

.inputContainer {
  position: sticky;
  top: 0;
  background: white;
}

.toggleText {
  font-size: map-get($font-sizes, 200);
}

.checkboxWrapper:last-of-type {
  padding-bottom: map-get($spacers, 2);
}
