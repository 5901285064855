@import '../../assets/util';

// default styles overwritten by response of getBarClassName
.bar {
  fill: $default-bar-color;
}
.barText {
  fill: $white;
}

.shortBar .barText {
  fill: $gray-900 !important;
}
