@import '../../assets/util';

.pager {
  white-space: nowrap;
}

.mini {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 49%;
  }
}
