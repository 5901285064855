// TODO get from typekit?
@font-face {
  font-family: 'open_sans';
  src: url('../fonts/OpenSans-Semibold-webfont.eot');
  src: url('../fonts/OpenSans-Semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold')
      format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans';
  src: url('../fonts/OpenSans-Regular-webfont.eot');
  src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'knockout';
  src: url('../fonts/Knockout-HTF29-JuniorLiteweight.eot');
  src: url('../fonts/Knockout-HTF29-JuniorLiteweight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Knockout-HTF29-JuniorLiteweight.woff2') format('woff2'),
    url('../fonts/Knockout-HTF29-JuniorLiteweight.woff') format('woff'),
    url('../fonts/Knockout-HTF29-JuniorLiteweight.ttf') format('truetype'),
    url('../fonts/Knockout-HTF29-JuniorLiteweight.svg#Knockout-HTF29-JuniorLiteweight')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'knockout';
  src: url('../fonts/Knockout-HTF27-JuniorBantamwt.eot');
  src: url('../fonts/Knockout-HTF27-JuniorBantamwt.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Knockout-HTF27-JuniorBantamwt.woff2') format('woff2'),
    url('../fonts/Knockout-HTF27-JuniorBantamwt.woff') format('woff'),
    url('../fonts/Knockout-HTF27-JuniorBantamwt.ttf') format('truetype'),
    url('../fonts/Knockout-HTF27-JuniorBantamwt.svg#Knockout-HTF27-JuniorBantamwt')
      format('svg');
  font-weight: bold;
  font-style: normal;
}
