@import '../../../assets/util';

.recordingSetupWarningBadgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  background-color: $fora-yellow;
  padding: 0.3rem;
  border-radius: 10px;
}

.recordingSetupWarningBadge {
  margin: 0;
}

.microphoneContainer {
  border: 1px solid #dae2e9;
  padding: 0.5rem 1rem;
  margin: 1rem 0rem 2rem 0rem;
  font-size: 13px;
}

.instructionsContainer {
  padding: 1rem;
}

ol {
  font-size: 15px;
}

.checkmarkIconWrapper {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  margin: 0.6rem 0rem 1rem 0rem;
}

.currentMicContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.selectMicContainer {
  margin-top: 2rem;
}

.noMicsAvailable {
  font-size: 1rem;
  font-weight: 600;
}

.toggleMicSelectButton {
  margin-bottom: 0.5rem;
}

.updateMicButton {
  margin: 0.2rem;
}

.currentMic {
  font-weight: 600;
}

.availableMicsContainer {
  margin-top: 0.3rem;
}

.micSelectFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.setUpFooter {
  display: flex;
  align-items: center;
  flex-direction: column;
}
