@import '../../assets/util';

.NotAuthorized {
  min-height: 100vh;

  .box-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box {
    width: 350px;
    padding: $box-padding-1;
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    background: #fff;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .login-logo {
    height: 45px;
    display: block;
    margin: 0 auto 3rem;
  }
}
