@import '../../assets/util';

.tableLabel {
  background: $gray-100;
  width: 240px;
}

.tooltipIcon {
  opacity: 0.3;
  float: right;
}

.tooltip :global(.tooltip-inner) {
  max-width: 400px;
  text-align: left;
}
