@import '../../../assets/util';

.durationBar {
  line-height: 1;
  padding: 2px 1rem;
  position: relative;
  font-size: 0.8rem;
  color: $gray-700;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.inner {
  display: flex;
  position: relative; // needed for progress bar to be behind
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: $gray-300;
}

.annotation {
  font-weight: bold;
}

.time {
  flex-grow: 1;
  text-align: right;
}

.elapsed,
.duration {
  color: $gray-700;
}
