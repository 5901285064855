@import '../../assets/util';

.withMargin {
  position: relative;
  display: flex;
}

.margin {
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 3); // same as a panel
  width: 200px;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  position: relative;
  user-select: none;
}
