// import default Reach tab styles
@import '../../node_modules/@reach/tabs/styles.css';

// customization of Reach UI tabs
// https://ui.reach.tech/styling/
[data-reach-tab-list] {
  background-color: transparent !important;
  margin: 0 -3rem;
  padding-left: 3rem;
  background: none !important;
  border-bottom: 1px solid $gray-400;
}

[data-reach-tab-panels] {
  padding: 1.5rem 0;
}

[data-reach-tab] {
  background-color: transparent;
  border: none;
  font-size: 14px;
  border-radius: $border-radius $border-radius 0 0;
  color: #666 !important;
  padding: $input-btn-padding-y (1.5 * $input-btn-padding-x);
  transition: $btn-transition;
}

[data-reach-tab]:hover {
  color: #333 !important;
  border-bottom: 2px solid $gray-400;
}

[data-reach-tab][data-selected] {
  background-color: transparent !important;
  border-bottom: 2px solid theme-color('primary') !important;
  color: $fora-purple !important;
  cursor: default;
  font-weight: bold;
}
