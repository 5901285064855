@import '../../assets/util';

.recordingRoomInfoBadgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  background-color: $fora-purple-lightest;
  color: $fora-purple-dark;
  padding: 0.3rem;
  border-radius: 10px;
}

.recordingRoomInfoBadge {
  margin: 0;
}

.recordingRoomNav {
  display: flex;
  justify-content: space-between;
}

.micLabel {
  font-size: 1rem;
}

.recordingRoomFooter {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.finishButton {
  margin-top: 0.5rem;
  max-width: 350px;
}
