@import '../../../assets/util';

.container {
  font-size: map-get($font-sizes, 400);
}

.elapsedTime {
  min-width: 38px;
  display: inline-block;
}
