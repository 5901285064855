@import '../../assets/util';

// for accessibility
@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

$card-transform: translate3d(25vw, 0, 0) translate3d(60%, 0, 0)
  rotate3d(0, 0, 1, 5deg);
@keyframes cardRollIn {
  from {
    opacity: 0;
    transform: $card-transform;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.rollIn {
  animation: cardRollIn 0.5s forwards;
}

@keyframes cardRollOut {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: $card-transform;
  }
}

.rollOut {
  animation: cardRollOut 0.5s forwards;
}

$stackSize: 4px;
.stack {
  position: relative;
  height: $expanded-highlight-card-height + 2 * $stackSize;

  .cardExit {
    &.topCard {
      @extend .rollOut;
    }
  }

  .cardEnter {
    &.topCard {
      @extend .rollIn;
    }
  }
}

.card {
  @extend .animated;
  position: absolute;
  z-index: 2; // prevent mapbox overlays from showing through
  left: 0;
  right: 0;
  height: $expanded-highlight-card-height;

  transition: all 500ms;
  top: 0;

  &.card-1 {
    top: 2 * $stackSize;
  }
  &.card-2 {
    top: $stackSize;
  }
}
