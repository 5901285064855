@import '../../assets/util';
@import '../../assets/registration';

.container {
  max-width: 80ch;
}

.termsMarkdown {
  // work around to let hashes to jump to headers without being blocked by the navbar
  h2,
  h3,
  h4,
  h5 {
    padding-top: $main-nav-height * 1.25;
    margin-top: -$main-nav-height * 1.25;
  }

  // scale everything down by one so first
  // changing the styles w/ CSS instead of using different
  // level tags because HTML is generated from markdown
  h2 {
    font-size: $h3-font-size;
  }
  h3 {
    font-size: $h4-font-size;
  }
  h4 {
    font-size: $h5-font-size;
  }
  h5 {
    font-size: $h5-font-size;
  }

  blockquote {
    font-style: italic;

    @include media-breakpoint-up(sm) {
      padding: 0 1.25rem;
    }
  }
}

.legalButtons {
  display: flex;
  justify-content: center;

  a {
    margin: 0 map-get($spacers, 4);
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    a {
      margin: 0;

      &:first-of-type {
        margin-bottom: map-get($spacers, 3);
      }
    }
  }
}

.guardian {
  max-width: 60ch;

  label {
    margin: 0 map-get($spacers, 3);
  }

  button {
    height: 48px;
    align-self: flex-end;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    label {
      margin: map-get($spacers, 3) 0;
    }
    button {
      align-self: stretch;
    }
  }
}
