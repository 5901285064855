@import '../../../../assets/util';
.tooltip {
  max-width: 250px;
}

.addButton {
  border: none;
  color: $fora-purple;
  box-shadow: none;
}
