@import '../../assets/util';

.errorContainer {
  min-height: 1.5rem; // one line height

  a {
    text-decoration: underline;
    color: map-get($theme-colors, 'danger');
  }
}
