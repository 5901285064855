@import '../../assets/util';

@each $color, $value in $theme-colors {
  .badge--pale {
    &.bg-#{$color} {
      color: $value;
      background-color: mix(#fff, $value, 92%) !important;
    }
  }
}
