@import '../../assets/util';

.leaf {
  background-color: transparent;
}
.selected {
  background-color: $teal !important;
}
.emphasize {
  background-color: $clr-yellow-500;
}
.searchHighlight {
  background-color: $gray-400;
}
.searchFocusedHighlight {
  background-color: $clr-lime-a700;
  font-weight: 700;
}
.editTranscriptContainer {
  overflow-y: auto;
  max-height: 80vh;
  padding: map-get($spacers, 2) map-get($spacers, 4);
}
.editTranscriptParentContainer {
  border: 2px solid $fora-purple;
  border-radius: $border-radius;
  background-color: $gray-100;
}
.resetting {
  height: 50vh;
  text-align: center;
}
