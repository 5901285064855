@import '../../assets/util';

.clearButton {
  position: absolute;
}

.container {
  position: relative;
}

.shape-round {
  .searchInput {
    padding-right: 4.75rem;
    border-radius: 3rem;
  }

  .clearButton {
    margin-top: 1px;
    width: 2rem;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .searchButton {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.85rem;
    right: 0.25rem;
  }
}

.shape-rect {
  .clearButton {
    right: 2rem;
    z-index: 10;
    font-size: 1rem;
    height: 100%;
  }
}

.size-lg {
  .searchInput {
    padding-right: 4.75rem;
    height: 3rem;
    border-radius: 3rem;
  }
  .searchButton {
    font-size: 1rem;
    right: 0.5rem;
  }
  .clearButton {
    right: 2.75rem;
  }
}
