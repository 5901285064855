@import '../../assets/util';

.backButton {
  :global(.Icon) {
    margin-right: map-get($spacers, 1) !important;
  }

  @include media-breakpoint-down(sm) {
    margin-left: -(map-get($spacers, 2));
  }
}

.formContent {
  p {
    text-align: center;
  }
}
