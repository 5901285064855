@import '../../../assets/util';

.icon {
  color: $gray-600;
  &:disabled {
    color: $gray-300;
    border: none !important;
    opacity: 1 !important;
    &:hover {
      color: $gray-300;
    }
  }
}

.findAndReplace {
  border: 1px solid $fora-purple;
  border-radius: 5px;
}
