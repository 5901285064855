@import '../../assets/util';

.dropdownButton {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  &:active,
  &.active,
  &:focus {
    background-color: $gray-300;
  }
}
