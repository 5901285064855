@import '../../../../assets/util';

.panel {
  padding: 0 !important;
  min-width: 60rem;
  max-height: 80vh;
  overflow: auto;
  position: relative;
}

.empty {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHeader {
  padding: 16px 0 !important;
  margin: 0 !important;
  font-size: map-get($font-sizes, 300) !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: white;
}
