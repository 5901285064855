@import '../../assets/util';

.dropdownContainer :global(.dropdown-toggle) {
  border: 1px solid $dropdown-border-color;
  border-radius: $border-radius-lg;
  box-shadow: none;
  padding: 0.25rem 0.8rem;
  min-width: $filter-width;

  &:hover:enabled {
    border-color: $fora-purple-lighter;
  }
  &:focus {
    border-color: $fora-purple;
  }

  &::after {
    font-size: 1.1rem; // fixes flat caret point on chrome
    margin-bottom: -2px; // adjust caret height
  }
}

.menu {
  min-width: 13rem;
  z-index: 1;
}

.toggleText {
  color: $gray-700;
}

.clearIcon {
  color: $gray-600;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

:global(.dropdown-item) .clearIcon {
  margin-right: 0.6rem;
}

.active {
  font-weight: 600;
}

// generate classes for each topic color
@for $i from 1 through length($topics-colors) {
  $value: nth($topics-colors, $i);
  $main-color: nth($value, 1);

  .color#{$i - 1} {
    :global(.dropdown-toggle) {
      border-color: $main-color !important;
    }

    .toggleText {
      color: $gray-800;
    }

    .toggleTopicIndicator,
    &.topicIndicator {
      color: $main-color;
    }
  }
}
