@import '../../assets/util';

.container {
  background: $white;
  padding-top: 1.5rem;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;

  &.toEdges {
    margin-left: -1rem;
    margin-right: -1rem;

    .words {
      padding: 0 1rem;
    }
  }
}

.words {
  padding: 0 0.75rem;
  font-size: 1.75em;
  min-height: 4.5em; // roughly 3lines to stop it jumping when one line happens
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  // for animation purposes
  position: relative;
  overflow: hidden;
}

// for animation, needs absolute position
.wordsInner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  color: $gray-900;

  transition: 0.5s transform ease-in-out;
  will-change: transform;
}

.compact {
  min-height: 6em; // roughly 4 lines
  line-height: 1.5;
  font-size: map-get($font-sizes, 400);
  margin-bottom: 0;
}

.word {
  position: relative;
}

.clickableWord {
  cursor: pointer;
  &:hover {
    color: $gray-700;
  }
}

.seekHighlightBar {
  background-color: $fora-purple-lighter;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1.7em;
  width: 2em;
  margin-top: -0.125em;
  padding: 0 0.2em;
  margin-left: -0.2em;
  box-sizing: content-box;
  transition: 100ms transform ease-in-out, 100ms width ease-in-out;
  will-change: transform, width;
}

.snippet {
  transition: 0.3s color;
  will-change: color;
}

.inactiveSnippet {
  color: $gray-500;
  transition: 0.3s color;
  will-change: color;
}

.emphasizedTerm {
  font-weight: bold;
}
