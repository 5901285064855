@import '../../../assets/util';

.word-medium {
  font-size: $h6-font-size;
}
.word-large {
  font-size: $h5-font-size;
}
.word-larger {
  font-size: $h4-font-size;
}
.word-x-large {
  font-size: $h3-font-size;
}
.word-xx-large {
  font-size: $h2-font-size;
}
.word-xxx-large {
  font-size: $h1-font-size;
}
