@import '../../../../assets/util';
.dropdownToggle {
  background: none !important;
  justify-content: space-between;
  min-width: 112px;
  border-radius: 8px;
  padding: 8px;
}

// Themes for pill colors
.lightBlue {
  background-color: lighten($clr-light-blue, 45%);
  color: $clr-blue-900;
}

.lightGreen {
  background-color: lighten($clr-green-a100, 5%);
  color: $clr-green-900;
}

.lightGray {
  background-color: lighten($gray-300, 1%);
  color: $gray-800;
}

.caret {
  margin-left: 0.675rem;
}

.checkIcon {
  margin-right: 0.825rem;
  color: $fora-purple;
}

.spinner {
  margin-left: 0.675rem;
}
